import moment from 'moment';

/**
 * @format
 */
export default class Utils {
  /**
   * Compare two string case insisitive
   * @param {String} str1
   * @param {String} str2
   * @return {Boolean}
   */
  static noCaseCmpStr(str1, str2) {
    if (typeof str1 !== 'string' || typeof str2 !== 'string') return false;

    return str1 === str2 || !!str1.match(new RegExp(`^${str2}$`, 'i'));
  }

  /**
   * Turns a string into an Object like { id, name }
   * @param {String} str
   * @return {Object|null} Object { id, name }
   */

  static strToItemObj(str) {
    return str ? { id: str, name: str } : null;
  }

  static arrayChunks(myArray, chunk_size) {
    var results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }

    return results;
  }

  static arrayAsyncProcess(array, process, interval = 100, chunkSize) {
    if (array.length && typeof process === 'function') {
      // let autoSz = 250;

      return new Promise(resolve => {
        let size =
          chunkSize ||
          (array.length >= 300 ? Math.ceil(array.length / 5) : array.length);
        let chunks = Utils.arrayChunks(array, Math.min(array.length, size));

        let len = chunks.length;
        let res = [];
        // console.log(`arrayAsyncProcess [${len} chunk(s)|${size} len|${interval}ms]`)
        let work = i =>
          setTimeout(() => {
            if (i < len) {
              // console.log(`arrayAsyncProcess chunk [${i+1} of ${len}]`)
              res.push(process(chunks[i], i, len));
              work(++i, interval);
            } else {
              resolve(res);
            }
          }, interval);

        work(0, 0);
      });
    }
    return Promise.resolve([]);
  }

  static flattenArray(array) {
    if (array && array.length) {
      return [].concat.apply([], array);
    }
    return array;
  }

  /**
   * filter array of object by propNames with filter
   * (lowercase and ignores whitespace)
   * @param propNames - coma separated string of prop names to filter for
   * @param filter - string to look for
   *
   */
  static filterArrayObj(array, propNames, filter) {
    if (!array || !array.length || !propNames) {
      return array;
    }

    const EXACT_TOKEN = '=';
    const lowercase = str => {
      return (str || '').toString().toLowerCase();
    };
    const removeWS = str => {
      return (str || '').replace(/\s/g, '');
    };
    const findItemProp = (item, prop) => {
      let propPath = prop.split('.');
      let value;
      propPath.forEach(part => {
        value = value ? value[part] : item[part];
      });
      return value
    };

    let _filter = lowercase(filter);
    let exactMatch = _filter.startsWith(EXACT_TOKEN) && _filter.length > 1;
    _filter = exactMatch ? _filter.substring(1) : removeWS(_filter);

    let props = propNames instanceof Array ? propNames : propNames.split(',');

    let filterByProp = item => {
      let found = false;
      props.forEach(prop => {
        let val = lowercase(findItemProp(item, prop)).trim();
        let match = exactMatch
          ? val === _filter
          : removeWS(val).indexOf(_filter) > -1;

        if (match) found = true;
      });
      return found;
    };

    return array.filter(filterByProp);
  }

  static groupArrayObj(array, field, cctv = null) {
    if (!array || !array.length || !field) return array;

    return array.reduce((grps, i) => {
      let g;
      cctv && field === 'timestamp' ? g = moment(i[field]).format('YYYY-MM-DD') : g = i[field];
      // let g = i[field];
      grps[g] = grps[g] || [];
      grps[g].push(i);
      return grps;
    }, {});

    // return groups;
  }
}
