/** @format */
import {
  DateTimeUtils,
  ConvertUtils
} from '@fonix/web-utils';
import {
  apiConfig
} from 'envconfigs/apiConfig';
import {
  DialogService
} from 'aurelia-dialog';

import userService from 'services/api/userService';
import apiService from 'services/api/apiService';

import authService from 'services/authService';
import backgroundService from 'services/backgroundService';
import {
  Redirect,
  Router
} from 'aurelia-router';
import {
  RouterConfig
} from 'configs';
import analyticsService from 'services/analyticsService';

import signalrService from 'services/signalr/signalrService';
import eventService, {
  EventsList
} from 'services/eventService';
import './app.scss';
/**
 *
 * This a router step class
 * It's run method is called on every route step to check for
 * proper permission for that route
 *
 */
class RouterStepPermissions {
  static inject() {
    return [Router, DialogService];
  }

  constructor(_Router, _DialogService) {
    this.router = _Router;
    this.dialogService = _DialogService;
  }

  run(navInstr, next) {
    let navs = navInstr.getAllInstructions().filter(i => i.config.permission || i.config.experimental);
    if (
      navs.length &&
      navs.some(i => {
        if (i.config.experimental) {
          return (userService.hasExperimentalFeatures()) ? false : true;
        }
        if (i.config.permission) {
          return !userService.hasPermission(i.config.permission);
        }
      })
    ) {
      return next.cancel(
        navInstr.previousInstruction ? undefined : new Redirect('')
      );
    }

    if (this.dialogService.controllers.length > 0) {
      var controller = this.dialogService.controllers[0];
      if (controller) {
        controller.cancel();
        return next.cancel(new Redirect(this.router.history.previousLocation, {
          trigger: false,
          replace: false
        }));
      }
    }
    return next();
  }
}

/**
 * This is the App view model for the App 'shell'
 * It contains all the top levels app properties,
 * everything in the app after login is a child of this
 *
 *
 * @see http://www.foursails.co/blog/aurelia-login-best-practices-pt-1/
 */
export class App {
  static inject() {
    return [Router, RouterConfig];
  }

  /**
   * constructor for the App view model
   * @param {class} Router from 'aurelia-router'
   * @param {RouterConfig} RouterConfig from configs
   */
  constructor(_Router, _RouterConfig) {
    //DI injections
    this.router = _Router;
    this.routerConfig = _RouterConfig;
    //
    this.userService = userService;
    this.api = apiService;

    this.showUpdateToast = false;
    this.activeRoute = null;
    //members
    /** @type {boolean} - true when app is ready to render **/
    this.isReady = false;
  }

  /**
   * activate is called by aurelia when view model is activated
   */

  activate() {
    this.routerConfig.configure({
      authorize: RouterStepPermissions
    });
  }

  attached() {
    eventService.subscribe(
      EventsList.AppRouterChanged,
      this.handleAppRouteChanged
    );

    eventService.subscribe(
      EventsList.SignalrbroadcastMessage,
      this.handleBroadcastMessage
    );

    eventService.subscribe(
      EventsList.ApiUpdateRequired,
      this.handleApiUpdateRequired
    );

    eventService.subscribe(
      EventsList.ApiUnauthorized,
      this.handleApiUnauthorized
    );

    this.loadAppData();


  }

  detached() {
    eventService.unsubscribe(
      EventsList.AppRouterChanged,
      this.handleAppRouteChanged
    );
    eventService.unsubscribe(
      EventsList.SignalrbroadcastMessage,
      this.handleBroadcastMessage
    );
    eventService.unsubscribe(
      EventsList.ApiUpdateRequired,
      this.handleApiUpdateRequired
    );
    eventService.unsubscribe(
      EventsList.ApiUnauthorized,
      this.handleApiUnauthorized
    );

  }

  loadAppData() {
    //check user token validity
    Promise.resolve(this.api.checkToken())
      .then(this.checkTokenResult.bind(this))
      .then(this.loadUserInfo.bind(this))
      .then((u) => {
        this.isReady = true;

        backgroundService.registerTask(
          'PINGSERVER',
          () => this.api.checkToken(),
          120 * 1000
        );
        //
        signalrService.connect(this.api.token);
      })
      .catch((e) => { });
  }

  loadUserInfo() {
    let pUserInfo = this.userService.getSelf();

    pUserInfo.then(u => {
      DateTimeUtils.setLocale(
        this.userService.getPreference('language') || 'en-gb'
      );
      ConvertUtils.setUnits(u.units);
      //
      analyticsService.setUser(u);
    });

    return pUserInfo;
  }

  /**
   * Called after loadingPromise is resolved or catched
   * If the token is invalid the user is redirected to Login
   *
   * @param {Boolean} isValid - true of false for the validity of the token
   * @return {Void}
   */
  checkTokenResult(isValid) {
    if (!isValid) {
      return Promise.reject(isValid);
    }
    //
    analyticsService.setToken(this.api.token);
    return Promise.resolve(isValid);
  }

  handleAppRouteChanged = name => {
    this.activeRoute = name;
  };

  /* global __VERSION__ */
  handleBroadcastMessage = data => {
    if (!data) return;

    let props = data.properties || {};
    if (props.type === 'update') {
      if (props.webAppVersion !== __VERSION__) {
        this.showUpdateToast = true;
      }
    }
  };

  handleApiUnauthorized = () => {
    authService.logout();

    this.router.navigate(`/`, {
      replace: true,
      trigger: false
    })
    this.router.reset();
    this.router.deactivate();

    let urlParams = {
      redirectUrl: window.location.origin
    }
    window.location.replace(`${apiConfig.fonixAccount}#/${window.btoa(JSON.stringify(urlParams))}`);
  }

  handleApiUpdateRequired = () => {
    this.showUpdateToast = true;
  };

  handleToastClose = () => {
    this.showUpdateToast = false;
  };

  reloadApp() {
    location.reload(true);
  }
}
