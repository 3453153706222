/** @format */
import {
  Aurelia,
  inject
} from 'aurelia-framework';
import authService from 'services/authService';
import {
  apiConfig
} from "configs";
/**
 * This is the Landing view mode for the Login 'shell'
 * It contains all the state of the 'app' before authentication
 *
 * @see http://www.foursails.co/blog/aurelia-login-best-practices-pt-1/
 */
@inject(Aurelia)
export class Landing {
  constructor(_Aurelia) {
    this.aurelia = _Aurelia;
    this.checkToken();
  }

  checkToken() {
    if (this.checkURLToken()) {
      return;
    }
    if (!authService.getToken()) {
      let urlParams = {
        redirectUrl: window.location.origin
      };
      window.location.replace(`${apiConfig.fonixAccount}#/${window.btoa(JSON.stringify(urlParams))}`);
      return;
    }
  }

  checkURLToken() {
    const urlToken = this.getQS('token');
    if (urlToken) {
      authService.setToken(urlToken);
      //force a full page refresh to remove current token from url
      //token is removed to keep url cleaner and to avoid cases where invalid tokens
      //are set over and over (while in the url)
      window.location.replace(window.location.origin);
    }
    return !!urlToken;
  }

  getQS = key => {
    const location = window.location;
    let _qs = location.search;

    if (!_qs.length && location.hash && location.hash.indexOf('#/') >= 0) {
      _qs = window.location.hash;
      _qs = _qs.substring(_qs.indexOf('#/') + 2);
    }

    if (!_qs.length) return;

    try {
      let base64Decoded = window.atob(_qs)
      if (JSON.parse(base64Decoded)) {
        let obj = JSON.parse(base64Decoded);

        return obj[key];
      }
    } catch (e) {
      return (key == _qs) ? true : false;
    }
  };
}
