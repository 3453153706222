import './icon.scss';
import {
  bindable
} from 'aurelia-framework';

export class Icon {
  @bindable success;
  @bindable error;

  constructor() {
  }

  attached() {
  }
}
