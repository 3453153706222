import moment from 'moment';
import cctvService from 'services/api/cctvService';
import { TranslationService } from 'services/translationService';

import './shared.scss';

export class Shared {
  static inject = [TranslationService];

  constructor(_TranslationService) {
    this.translations = _TranslationService;
    this.cctvService = cctvService;

    this.loading = false;
    this.success = false;
    this.error = false;
    this.item = null;
    this.fonixUrl = 'https://www.fonixtelematics.com/';
    this.detailsText = null;
  }

  attached() {
    try {
      let url = window.location.hash.substring(window.location.hash.indexOf('#/shared/') + 9);
      this.item = JSON.parse(atob(url).substring(atob(url).indexOf('{')));

      this.cctvService.checkDownloadHead(this.item.Id).then(code => {
        if (code !== 200) {
          this.error = true;
        } else {
          this.checkExpirationDate(this.item);
        }
      });
    } catch (e) {
      this.error = true;
    }
  }

  checkExpirationDate = (item) => {
    if (!item.Expires || moment(item.Expires) < moment()) {
      this.error = true;
    } else {
      this.detailsText = this.translations.getCap('expires_in')
        + ' '
        + (moment(item.Expires).diff(moment(), 'hour') > 24 ?
          moment(item.Expires).diff(moment(), 'day') + 1 + ' days'
          : moment(item.Expires).diff(moment(), 'day') + 1 + ' day');
    }
  }

  startDownload = (shareItem) => {
    this.loading = true;
    if (shareItem.Source === 'media' && shareItem.Id) {
      this.cctvService.encodeMediaId(shareItem.Id, shareItem.Source).then(url => {
        window.open(url, "_self");

        this.detailsText = this.translations.getCap('transfer_begin')
        this.success = true;
        this.loading = false;
      });
    }
  }
}
